import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';

import * as style from '../styles/indexPage.module.scss';
import logo from '../images/logo.png';

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'h-100 h-100',
        }}
        bodyAttributes={{
          class: ['w-100 h-100 d-flex justify-content-center align-items-center', style.indexStyle].join(' '),
        }}
      >
        <title>FootballAnalysis.gr | Under Construction</title>
      </Helmet>
      <div className="d-flex w-100 h-100">
        <div className="container text-center">
          <div className="mb-5">
            <img src={logo} alt="FootballAnalysis.gr" className="img-fluid" />
          </div>
          <div className={['mb-5', style.descriptionText].join(' ')}>
            IS <span>COOMING SOON</span>
            <br />
            STAY TUNED!
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <a href="https://www.facebook.com/footballanalysis.gr/" title="FootballAnalysis.gr - Facebook Page" target="_blank" rel="noreferrer" className={['mx-2 px-2 py-1 border rounded text-decoration-none', style.socialMediaLink, style.facebookLink].join(' ')}>
              <FontAwesomeIcon icon={['fab', 'facebook-f']} className="me-2" /> Facebook
            </a>
            <a href="https://twitter.com/f_analysis_gr" title="FootballAnalysis.gr - Twitter" target="_blank" rel="noreferrer" className={['mx-2 px-2 py-1 border rounded text-decoration-none', style.socialMediaLink, style.twitterLink].join(' ')}>
              <FontAwesomeIcon icon={['fab', 'twitter']} className="me-2" /> Twitter
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
